
$(document).ready(function () {

    $('#gratis').click(function (event) {
        if ($(this).is(':checked')) {
            $(".price-wrapper").hide();
            $('select[name=rekening]').parent().hide();
        } else {
            $(".price-wrapper").show();
            $('select[name=rekening]').parent().show();
            $('.link-toggle-price').click();
        }
    });

    if ($('#vertrek_zones').val() == "0") {
        $(".price-wrapper").toggle();
        $('select[name=rekening]').parent().toggle();
        $('#gratis').attr('checked', 'checked');
    }
    $('#vertrek_zones').show();
    $('#retour_zones').show();
    $('#zones_vertrek').show();
    $('#zones_retour').show();

    $('#retour_totaal').hide();
    $('#vertrek_totaal').hide();



    // the 1 sec interval
    $(".rotate").click(function () {
        $(this).html('<img src="/img/refresh.gif" />');
        determineDeparture();
        determineRetour();
        setTimeout(function () {
            $('.rotate').html('<img src="/img/refresh_stopped.gif" class="opties" title="Prijs berekenen" />');
        }, 1500);
    });

    $("#container.verwerk .toggle-price").hide();

    $('.link-toggle-price').click(function (event) {
        determineDeparture();
        determineRetour();
        $(this).next('.link-toggle-price').addClass("active");
        setTimeout(function () {
            $('.link-toggle-price').next('.toggle-price').slideDown(200);
            $('.link-toggle-price').contents('img.expander').attr({
                src: "",
                alt: ""
            });
        }, 500);
        $(this).contents('img.expander').attr({
            src: "assets/_img/refresh.gif",
            alt: "(Sluiten)"
        });
        //alert('on');
    }, function (event) { });

    $(".toggle").hide();

    if (!$("#vertrek_alt_adres").val()) {
        $(".alternatief1").hide();
    }
    if (!$("#bestemming_alt_adres").val()) {
        $(".alternatief2").hide();
    }
    if (!$("#alt_retour_adres").val()) {
        $(".alternatief3").hide();
    }

    $(".showalternatief1").click(function () {
        $(".alternatief1").slideToggle(200);
    });
    $(".showalternatief2").click(function () {
        $(".alternatief2").slideToggle(200);
    });
    $(".showalternatief3").click(function () {
        $(".alternatief3").slideToggle(200);
    });


    $(".input").autocomplete("./ajaxIndex.php?p=autocomplete_ajax", { autoFill: true, selectOnly: true });

    $("#update_price").click(function () {
        determineDeparture();
        determineRetour();
    });

    //hide the all of the element with class msg_body
    $(".info_body").hide();

    $(".infobox").mouseover(function () {
        $(".info_body").slideDown('fast');
    });

    $(".infobox").mouseout(function () {
        $(".info_body").slideUp('fast');
    });



    $('.link-toggle').toggle(function (event) {
        event.preventDefault();
        $(this).next('.toggle').slideToggle(200);
        $(this).next('.link-toggle').addClass("active");
        $(this).contents('img.expander').attr({
            src: "/img/close.png",
            alt: "(Sluiten)"
        });
        //alert('on');
    }, function (event) { // same here
        event.preventDefault();
        $(this).next('.toggle').slideToggle(200);
        $(this).contents('img.expander').attr({
            src: "/img/open.png",
            alt: "(Open)"
        });;
    });

    function determineDeparture() {
        //alert('determineDep');
        var vertrek = "";
        var bestemming = "";
        var wmo = $(' #temp').val();
        var jaar = $(' #jaar').val();
        //$('#vertrek_totaal').val($('#pr_woonplaats').val());
        //$('#retour_totaal').val($('#pr_bs_woonplaats').val());


        if ($('#vertrek_alt_checked').attr('checked') == false) {
            var vertrek = $('#pr_woonplaats').val();
        } else {
            var vertrek = $('#vertrek_alt_plaats').val();
        }

        if ($('#bestemming_alt_checked').attr('checked') == false) {
            var bestemming = $('#pr_bs_woonplaats').val();
        } else {
            var bestemming = $('#bestemming_alt_plaats').val();
        }

        jQuery.post('./ajaxIndex.php?p=define_zones', { 'v': vertrek, 'b': bestemming, 'wmo': wmo, 'jaar': jaar }, function (data) {
            $('#vertrek_zones').val($.trim(data));
        });
    }

    function determineRetour() {
        var vertrek = "";
        var bestemming = "";
        var wmo = $(' #temp').val();
        var jaar = $(' #jaar').val();

        if ($('#bestemming_alt_checked').attr('checked') == false) {
            var vertrek = $(' #pr_bs_woonplaats').val();
        } else {
            var vertrek = $(' #bestemming_alt_plaats').val();
        }

        if ($('#alt_retour_checked').attr('checked') == false) {
            var bestemming = $(' #pr_woonplaats').val();
        } else {
            var bestemming = $(' #alt_retour_plaats').val();
        }

        jQuery.post('./ajaxIndex.php?p=define_zones', { 'v': vertrek, 'b': bestemming, 'wmo': wmo, 'jaar': jaar }, function (data) {
            $('#retour_zones').val($.trim(data));
        });
    }

    $('#vertrek_totaal').keyup(function () {
        $('#vertrek_totaal_temp').val($('#vertrek_totaal').val());
    });
    $('#retour_totaal').keyup(function () {
        $('#retour_totaal_temp').val($('#retour_totaal').val());
    });
    //Numeric restrictie

    $('#vertrek_prijs1').change(function () {
        if ($('#vertrek_prijs1').val() == 1) {
            $('#vertrek_totaal').val('');
            $('#vertrek_totaal').attr('disabled', '');
            $('#vertrek_zones').show();
            $('#vertrek_zones').attr('disabled', '');
            $('#zones_vertrek').show();
            $('#zones_vertrek').attr('disabled', '');
        } else {
            determineDeparture();
            // $('#vertrek_totaal').attr('disabled', 'disabled');
            // $('#vertrek_zones').show();
            // $('#vertrek_zones').attr('disabled', 'disabled');
            // $('#zones_vertrek').show();
            // $('#zones_vertrek').attr('disabled', 'disabled');
        }
    });



    $('#retour_prijs1').change(function () {
        if ($('#retour_prijs1').val() == 1) {
            $('#retour_totaal').val('');
            $('#retour_totaal').attr('disabled', '');
            $('#retour_zones').show();
            $('#retour_zones').attr('disabled', '');
            $('#zones_retour').show();
            $('#zones_retour').attr('disabled', '');
        } else {
            determineRetour();
            // $('#retour_totaal').attr('disabled', 'disabled');
            // $('#retour_zones').hide();
            // $('#retour_zones').attr('disabled', 'disabled');
            // $('#zones_retour').hide();
            // $('#zones_retour').attr('disabled', 'disabled');
        }
    });

    $('#aantal_p').change(function () {
        determineDeparture();
        determineRetour();
    });

    $('.suggestBox_vertrek').click(function () {
        determineDeparture();
        determineRetour();
    });

    $('.suggestBox_bestemming').click(function () {
        determineDeparture();
        determineRetour();
    });

    $('.ac_results').click(function () {
        determineDeparture();
        determineRetour();
    });


    /*$('.links').hover(function(){
        determineDeparture();
        determineRetour();
    });

    $('.midden').hover(function(){
        determineDeparture();
        determineRetour();
    });

/*	$(":input").hover(function(){
        determineDeparture();
        determineRetour();
    });*/

    $('#vertrek_alt_plaats').keyup(function () {
        determineDeparture();
        determineRetour();
    });

    $('#bestemming_alt_plaats').keyup(function () {
        determineDeparture();
        determineRetour();
    });


});

// Vertrek punt suggestbox
function lookup_vertrek(inputString) {
    if (inputString.length == 0) {
        // Hide the suggestion box.
        $('#suggestions_vertrek').hide();
    } else {
        $.post("./ajaxIndex.php?p=ajax_vertrek", { queryString: "" + inputString + "" }, function (data) {
            if (data.length > 0) {
                $('#suggestions_vertrek').show();
                $('#autoSuggestList_vertrek').html(data);
            }
        });
    }
} // lookup

function lookup_donateur(inputString) {
    if (inputString.length == 0) {
        // Hide the suggestion box.
        $('#suggestions_vertrek').hide();
    } else {
        $.post("./ajaxIndex.php?p=ajax_donateur", { queryString: "" + inputString + "" }, function (data) {
            if (data.length > 0) {
                $('#suggestions_vertrek').show();
                $('#autoSuggestList_vertrek').html(data);
            }
        });
    }
} // lookup


function fill_vertrek(title, id, adres, woonplaats, wmo) {
    $('#klantnaam').val(title);
    $('#haalId').val(id);
    $('#pr_adres').val(adres);
    $('#pr_woonplaats').val(woonplaats);
    $('#wmo').val(wmo);

    if (wmo != '') {
        $('#temp').val('Ja');
        verwerkDisplayPers();
    } else {
        $('#temp').val('Nee');
        verwerkDisplayPers();
    }

    setTimeout("$('#suggestions_vertrek').hide();", 200);
}

// Bestemming punt suggestbox
function lookup_bestemming(inputString) {
    if (inputString.length == 0) {
        // Hide the suggestion box.
        $('#suggestions_bestemming').hide();
    } else {
        $.post("./ajaxIndex.php?p=ajax_bestemming", { queryString: "" + inputString + "" }, function (data) {
            if (data.length > 0) {
                $('#suggestions_bestemming').show();
                $('#autoSuggestList_bestemming').html(data);
            }
        });
    }
} // lookup

function fill_bestemming(title, id, adres, woonplaats, wmo) {
    $('#bestemmingnaam').val(title);
    $('#brengId').val(id);
    $('#pr_bs_adres').val(adres);
    $('#pr_bs_woonplaats').val(woonplaats);

    setTimeout("$('#suggestions_bestemming').hide();", 200);
}


$(" #error").hide();

function remote(input, el) {
    jQuery.ajax({
        type: "POST",
        url: "./ajaxIndex.php?p=autocomplete_ajax_check",
        data: 'q=' + input,
        cache: false,
        success: function (msg) {
            // console.log(el);
            // console.log(msg);
            $(el).val(msg);
            // if (msg == false) {
            //     $(' #error').slideDown(function() {
            //         $(this).empty();
            //         $(this).html("<strong>" + input + "</strong> is niet bekend in het systeem.<br />Gelieve het boeken van deze rit af te breken en Anneke of Ans op de hoogte te stellen van de onbekende plaats en de daarbij behorende afspraak.");
            //     });
            //     alert("Plaats komt niet overeen met de database");
            //
            //     //$('#submit').attr('disabled', 'disabled');
            //     return false;
            // } else {
            //     $(" #error").empty();
            //     $(" #error").hide();
            //     //$('#submit').attr('disabled', '');
            // }
        },
    });
}


function checkform() {
    //var valid = true;
    //alert(document.findfield.bestemming_alt_adres.value);
    var fout = "";

    if (document.findfield.haalId.value == "") {
        fout += ("U dient een klant op gegeven.\n");
    }

    if (document.findfield.vertrek_alt_checked.checked == true) {
        if (document.findfield.vertrek_alt_plaats.value == "") {
            fout += ("Geen alternatieve plaats ingevuld bij vertrek.\n");
            //alert("Geen alternatieve plaats ingevuld bij vertrek");
        }
        if (document.findfield.vertrek_alt_adres.value == "") {
            fout += ("Geen alternatieve adres ingevuld bij vertrek.\n");
            //alert("Geen alternatieve plaats ingevuld bij vertrek");
        }
    }

    if (!$(' #gratis').is(':checked')) {
        if ($('.toggle-price').is(':hidden')) {
            fout += ("Klik op Bereken prijs.\n");
        }
    }

    if (document.findfield.bestemming_alt_checked.checked == true) {
        if (document.findfield.bestemming_alt_plaats.value == "") {
            fout += ("Geen alternatieve plaats ingevuld bij bestemming.\n");
            //alert("Geen alternatieve plaats ingevuld bij bestemming");
        }
        if (document.findfield.bestemming_alt_adres.value == "") {
            fout += ("Geen alternatieve plaats ingevuld bij bestemming.\n");
            //alert("Geen alternatieve plaats ingevuld bij bestemming");
        }
    }

    if (document.findfield.alt_retour_checked.checked == true) {
        if (document.findfield.alt_retour_plaats.value == "") {
            fout += ("Geen alternatieve plaats ingevuld bij alternatieve retour.\n");
            //alert("Geen alternatieve plaats ingevuld bij alternatieve retour");
        }
        if (document.findfield.alt_retour_adres.value == "") {
            fout += ("Geen alternatieve plaats ingevuld bij alternatieve retour.\n");
            //alert("Geen alternatieve plaats ingevuld bij alternatieve retour");
        }
    }

    if (document.findfield.brengId.value == "" && document.findfield.bestemming_alt_checked.checked == false || document.findfield.bestemming_alt_adres.value == "" && document.findfield.brengId.value == "") {
        fout += ("U dient nog een bestemming op te geven.\n");
    }

    if (document.findfield.vertrek_datum.value == document.findfield.bestemming_datum.value) {
        if (document.findfield.vertrek_tijd.value > document.findfield.bestemming_tijd.value && document.findfield.bestemming_tijd.value != "14:14" && document.findfield.bestemming_tijd.value != "12:12") {
            fout += ("Het is niet mogelijk de aankomsttijd eerder te plannen dan de vertrektijd.\n");

        }

        if (document.findfield.vertrek_tijd.value == document.findfield.bestemming_tijd.value) {
            fout += ("U dient de aankomsttijd later in te plannen dan de vertrektijd.\n");
        }
    }

    if (fout == "") {
        return true;
    } else {
        fout += ("\nOm de afspraak met succes te verwerken, gelieve bovenstaande punt(en) aan te passen.");
        alert(fout);
        return false;
    }
}



function updateTotal() {
    var totalVal = $('#totaal').val();
    var totalVal1 = $('#totaal1').val();
    var aantalP = parseInt($('#aantal_p').val());
    var prijsVal = parseInt($('#vertrek_zones').val());
    var prijsVal_retour = parseInt($('#retour_zones').val());

    console.log(aantalP, prijsVal);


    $('#totaal').val(number_format(aantalP * prijsVal, 2, ',', ''));
    $('#totaal1').val(number_format(aantalP * prijsVal_retour, 2, ',', ''));
}

var options = {
    script: "./ajaxIndex.php?p=z_haal&json=true&",
    varname: "input",
    json: true,

    callback: function (obj) {
        document.getElementById('wmo').value = obj.wmo;
        document.getElementById('haalId').value = obj.id;

        if (document.getElementById('wmo').value != '') {
            document.getElementById('temp').value = 'Ja';
            verwerkDisplayPrijs();
        } else {
            document.getElementById('temp').value = 'Nee';
            verwerkDisplayPrijs();
        }

    }
};

/*
var as_json = new AutoSuggest('testinput', options);
var options_xml = { script:"ajaxIndex.php?p=z_haal", varname:"input" };
var as_xml = new AutoSuggest('testinput_xml', options_xml);
*/
// function displayPrijs() {
//     var varhtml = "";
//     $.get("./ajaxIndex.php?p=ajax_prijzen", { temp: $(" #temp").attr("value") }, function(data) {
//         varhtml += data;
//         $(" #vertrek_prijs").html(varhtml);
//         $(" #retour_prijs").html(varhtml);
//         // success: updateTotal();
//     });
//
// }
//
function verwerkDisplayPers() {
    var varhtml = "";
    $.get("./ajaxIndex.php?p=ajax_personen", { temp: $(" #temp").attr("value") }, function (data) {
        varhtml += data;
        $(" #aantal_p").html(varhtml);
        // success: updateTotal();
    });

}
//
